<template>
  <div>
    <loading v-if="loading" />
    <div v-if="!loading" class="row">
      <div class="col-md-12">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle btn-sm py-2 float-end"
            type="button"
            id="dropdownMenuButton-section"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions
          </button>
          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton-section"
          >
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="setStatus('approved')"
              v-if="agency.status == 'pending' || agency.status == 'denied'"
              >Approve</a
            >
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="setStatus('denied')"
              v-if="agency.status == 'pending' || agency.status == 'approved'"
              >Deny</a
            >
            <a class="dropdown-item" href="#" @click.prevent="confirmDelete"
              >Delete</a
            >
          </div>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-success dropdown-toggle btn-sm py-2 float-end me-2"
            type="button"
            id="dropdownMenuButton-section"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Dosuments
          </button>
          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton-section"
          >
            <a
              :href="agency.registration"
              class="dropdown-item"
              target="_blank"
              v-if="agency.registration"
            >
              <i class="lni lni-download me-2"></i>
              Download Registration Doc
            </a>
            <a
              href="#"
              class="dropdown-item"
              target="_blank"
              v-if="!agency.registration"
            >
              <i class="lni lni-warning me-2"></i>
              No Registration Doc
            </a>
            <a
              :href="agency.approval"
              class="dropdown-item"
              target="_blank"
              v-if="agency.approval"
            >
              <i class="lni lni-download me-2"></i>
              Download Approval Doc
            </a>
            <a
              href="#"
              class="dropdown-item"
              target="_blank"
              v-if="!agency.approval"
            >
              <i class="lni lni-warning me-2"></i>
              No Approval Doc
            </a>
          </div>
        </div>
        <a
          href="#"
          class="btn btn-info btn-sm py-2 float-end me-2"
          @click.prevent="show_contact_requests = true"
        >
          Contact Requests
        </a>
        <h2>{{ agency.name }}</h2>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="text-center pt-5">
            <img :src="agency.avatar" :alt="agency.name" height="100" />
            <h4 class="mt-3">{{ agency.name }}</h4>
          </div>
          <div class="card-body">
            <ul class="agency-info">
              <li>
                <strong>Status:</strong>
                <span
                  :class="`ms-2 badge badge-${
                    agency.status == 'pending'
                      ? 'warning'
                      : agency.status == 'approved'
                      ? 'success'
                      : 'danger'
                  }`"
                  >{{ agency.status }}</span
                >
              </li>
              <li><strong>Email:</strong> {{ agency.email }}</li>
              <li><strong>Phone Number:</strong> {{ agency.phone_number }}</li>
              <li><strong>Whatsapp:</strong> {{ agency.whatsapp }}</li>
              <li>
                <strong>Property Ads:</strong> {{ agency.agency_ads }}
                <small class="text-muted"
                  >({{ agency.agency_views }} views)</small
                >
              </li>
            </ul>
            <hr />
            <h6 class="text-muted">Social Presence</h6>
            <ul class="social-presence-icons mt-3">
              <li v-for="(val, type) in agency.socials" :key="`social-${type}`">
                <a
                  :class="`${type}`"
                  :href="val"
                  :title="type"
                  v-if="val"
                  target="_blank"
                  ><i :class="`lni lni-${type}`"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Members</h4>
          </div>
          <div class="card-body">
            <table class="table table-bodred">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="agency.users.length == 0">
                  <td colspan="5">{{ agency.name }} has no users</td>
                </tr>
                <tr v-for="(user, i) in agency.users" :key="`user-${i}`">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'admin.users.show',
                        params: { id: user.id },
                      }"
                      >{{ user.name }}</router-link
                    >
                  </td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  <td>{{ user.role }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">
            <h4 class="card-title mb-0">Property Ads</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Region</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Views</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="agency.properties.length == 0">
                  <td colspan="8">There is no data to display</td>
                </tr>
                <tr
                  v-for="(property, i) in agency.properties"
                  :key="`property-${i}`"
                >
                  <td>{{ i + 1 }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'admin.properties.show',
                        params: { id: property.id },
                      }"
                      >{{ property.name }}</router-link
                    >
                  </td>
                  <td>{{ property.type }}</td>
                  <td>{{ property.region }}</td>
                  <td>{{ property.amount }}</td>
                  <td>
                    <span
                      :class="`btn btn-sm rounded-pill btn-${
                        property.is_paid ? 'success' : 'danger'
                      }`"
                      >{{ property.is_paid ? "Yes" : "No" }}</span
                    >
                  </td>
                  <td>{{ property.views }}</td>
                  <td>
                    <span
                      :class="`btn btn-sm rounded-pill btn-${
                        property.status == 'pending'
                          ? 'warning'
                          : property.status == 'active'
                          ? 'success'
                          : 'danger'
                      }`"
                      >{{ property.status }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <modal
        :show="show_contact_requests"
        @close="show_contact_requests = false"
      >
        <template slot="header">Contact Requests</template>
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Content</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="agency.contact_requests.length == 0">
              <td colspan="4">There is no data to display</td>
            </tr>
            <tr v-for="(line, i) in agency.contact_requests.length" :key="`line-${i}`">
              <td>{{ line.type }}</td>
              <td>{{ line.name }}</td>
              <td>{{ line.content }}</td>
              <td>{{ line.date }}</td>
            </tr>
          </tbody>
        </table>
      </modal>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      agency: {},
      loading: true,
      show_contact_requests: false,
    };
  },

  methods: {
    confirmDelete() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete agency!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy();
        }
      });
    },

    setStatus(status) {
      let data = {
        status: status,
      };

      this.$axios
        .put(`/api/v1/admin/agencies/${this.$route.params.id}`, data)
        .then(() => {
          this.fetch();
        });
    },

    fetch() {
      this.loading = true;
      this.axios
        .get(`/api/v1/admin/agencies/${this.$route.params.id}`)
        .then((response) => {
          this.agency = response.data.agency;
          this.loading = false;
        });
    },

    destroy() {
      this.axios
        .delete(`/api/v1/admin/agencies/${this.$route.params.id}`)
        .then(() => {
          this.$swal({
            title: "Success",
            text: "The agency has been deleted",
            type: "success",
          });
          this.$router.push({ name: "admin.agencies.index" });
        });
    },
  },
};
</script>
